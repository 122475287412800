<template>
  <div id="tool-wrap">
    <header class="tool-header">
      <div class="header">
        <h3><slot name="title"> 搜索下拉词推荐</slot></h3>
      </div>
    </header>
    <div class="tool-content">
      <div class="tool-box">
        <div class="label">种子词：</div>
        <div class="wordArea">
          <a-textarea
            v-model="keyWords"
            placeholder="请输入种子词，每一行一个词"
            :rows="10"
          />
          <div>请输入种子词,每一行一个词</div>
        </div>
      </div>

      <div class="tool-box" v-if="showLevel">
        <div class="label">循环次数：</div>
        <a-input-number id="inputNumber" v-model="count"    :min="1"  :max="3" />
      </div>
      <div
        style="
          text-align: right;
          width: 50%;
          padding-right: 24px;
          margin-top: 24px;
        "
      >
        <a-button type="primary" @click="submit()"> 提交任务 </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tool",
  data() {
    return {
      count: 1,
      keyWords: undefined,
    };
  },
  props: {
    showLevel: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    
  },
  methods: {
    submit() {
      this.$parent.handleSubmit();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/common/variables.less";
@import "../styles/common/mixins.less";
#tool-wrap {
  width: 40%;
  height: 100%;
  h4 {
    margin-top: 12px;
  }
  position: relative;
  height: 100%;
  background: @w;
  padding: 24px 20px 24px 32px;
  .ant-popover-buttons {
    display: none;
  }
  .tool-header {
    .header {
      display: flex;
      justify-content: space-between;
      font-family: @pm;
      font-size: 14px;
      color: @tc;
      line-height: 24px;
    }
  }
  .tool-content {
    margin-top: 24px;
    .tool-box {
      width: 99.9%;
      display: flex;
      margin-top: 24px;
      .label {
        width: 100px;
        text-align: right;
      }
      .wordArea {
        width: calc(100% - 120px);
        padding-bottom: 12px;
      }
    }
  }
}
</style>

