var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"task-wrap"}},[_c('header',{staticClass:"task-header"},[_c('div',{staticClass:"header"},[_c('h3',[_vm._t("title",[_vm._v("任务中心")])],2)])]),_c('div',{staticClass:"task-content"},[_c('a-table',{staticClass:"table",attrs:{"rowKey":function (record, index) { return index; },"columns":_vm.columns,"data-source":_vm.data,"pagination":{
        total: _vm.total,
        defaultCurrent: 1,
        pageSize: this.pageSize,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: function (total) { return ("一共 " + total + " 条"); },
      }},on:{"change":_vm.sizeChange},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return _c('div',{},[_c('span',{class:['op', { noOpt: !(record.status === '已生成') }],on:{"click":function($event){record.status === '已生成' ? _vm.handleOp(record) : null}}},[_vm._v("下载")])])}},{key:"id",fn:function(text, record, index){return _c('div',{},[_vm._v(" "+_vm._s(index + 1)+" ")])}}])}),_c('a',{attrs:{"href":_vm.href,"download":"","id":"assist"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }