<template>
  <div id="task-wrap">
    <header class="task-header">
      <div class="header">
        <h3><slot name="title">任务中心</slot></h3>
      </div>
    </header>
    <div class="task-content">
      <a-table
        :rowKey="(record, index) => index"
        @change="sizeChange"
        class="table"
        :columns="columns"
        :data-source="data"
        :pagination="{
          total: total,
          defaultCurrent: 1,
          pageSize: this.pageSize,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `一共 ${total} 条`,
        }"
      >
        <div slot="operation" slot-scope="text, record">
          <span
            :class="['op', { noOpt: !(record.status === '已生成') }]"
            @click="record.status === '已生成' ? handleOp(record) : null"
            >下载</span
          >
        </div>
        <div slot="id" slot-scope="text, record, index">
          {{ index + 1 }}
        </div>
      </a-table>
      <a :href="href" download="" id="assist"></a>
    </div>
  </div>
</template>

<script>
import { getTuociReqs, getMobiReqs, getJietuReqs } from "../api/auth.js";
import { authorityMixin } from "../utils/mixin";
const columns = [
  {
    title: "任务ID",
    key: "ID",
    scopedSlots: { customRender: "id" },
    dataIndex: "name",
  },
  {
    title: "任务名称",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "任务状态",
    key: "status",
    dataIndex: "status",
    width: 200,
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    width: 150,
  },
];
export default {
  name: "Task",
  data() {
    return {
      getTuociReqs,
      getMobiReqs,
      getJietuReqs,
      href: undefined,
      columns,
      data: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      timeCode:0
    };
  },
    props: {
    code: {
      type: String,
      default: 'getTuociReqs',
    },
  },
  mixins: [authorityMixin],
  created() {
    this.getList();
    window.timer && clearInterval(window.timer);
    window.timer = setInterval(() => {
      this.getList();
    }, 7000);
    this.timeCode=window.timer
  },
  destroyed() {
    this.timeCode && clearInterval(this.timeCode);
  },
  methods: {
    sizeChange(page) {
      this.pageNum = page.pageSize === this.pageSize ? page.current : 1;
      this.pageSize = page.pageSize;
      this.getList();
    },
    handleOp({ url }) {
      this.downloadAssist(
        process.env.VUE_APP_BASE_URL_SMARTTOOL + encodeURI(url)
      );
    },
    downloadAssist(url) {
      const ele = document.getElementById("assist");
      ele.href = url;
      setTimeout(function () {
        ele.click();
      }, 1000);
    },
    getList() {
      let data = {
        params: {
          uid: this.loginUser.uid,
          config: { simplify: false },
        },
      };
   
      (this[this.code])(data).then((res) => {
        this.data = res;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/common/variables.less";
@import "../styles/common/mixins.less";
#task-wrap {
  width: 60%;
  height: 100%;
  h4 {
    margin-top: 12px;
  }
  position: relative;
  height: 100%;
  background: @w;
  padding: 24px 32px 24px 0px;

  .task-header {
    .header {
      display: flex;
      justify-content: space-between;
      font-family: @pm;
      font-size: 14px;
      color: @tc;
      line-height: 24px;
    }
  }
  .task-content {
    margin-top: 24px;
    .task-box {
      width: 99.9%;
      display: flex;
      .label {
        width: 100px;
        text-align: right;
      }
      .wordArea {
        width: calc(100% - 120px);
        padding-bottom: 12px;
      }
    }
  }
  .op {
    color: @link;
    cursor: pointer;
  }
  .noOpt {
    color: rgba(0, 0, 0, 0.45);
    cursor: default;
    user-select: none;
  }
}
</style>

